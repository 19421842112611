
$heading-color: #373e4a;
$para-color: #565656;

$base-color: #4380e4;
$base-color-two: #2A3042;

// bg-color
$bg-one: #071e3e;
$bg-two: #02014a;

// border-color
// $border-color: #dbe6f3
$border-color: #e0e0e0;

// color variable for Bootstrap
$primary: #7367f0;
$secondary: #868e96;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$info: #1e9ff2;
$dark: #10163A;
$light: #bcc7da;
$muted: #a0a0a0;
