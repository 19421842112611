/* table css start */
.custom--table {
  thead {
    @extend %base-color-two;
    th {
      border-bottom: 1px solid #f0f1f5 !important;
      border-top: none;
      padding: rem(12px) rem(20px);
      color: #fff;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      font-weight: 600;
      &:first-child {
        text-align: left;
        @include border-radius(8px 0 0 8px);
 }        // padding-left: 0
      &:last-child {
        text-align: right;
        @include border-radius(0 8px 8px 0);
 } } }        // padding-right: 0
  tbody {
    td {
      border-top: none;
      border-bottom: 1px solid #f0f1f5;
      padding: 12px 20px;
      color: #7e7e7e;
      text-align: center;
      &:first-child {
        text-align: left;
 }        // padding-left: 0
      &:last-child {
        text-align: right;
 } }        // padding-right: 0
    tr {
      @include transition(all 0.3s);
      &:hover {
        @include transform(translateY(-5px));
        box-shadow: 0 5px 15px rgba(#000000, 0.05);
        background-color: #ffffff;
        td {
          border-bottom-color: transparent; } }
      &:last-child {
        td {
          border-bottom: none; } } } } }
// table responsive
[data-label] {
  position: relative;
  &::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #000000;
    top: 0;
    left: 0;
    padding: rem(13px) rem(15px);
    display: none;
    font-size: rem(12px); } }
.table-responsive--md {
  @media (max-width: 991px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: #f7f7f7; }
        td {
          padding-right: 15px;
          &:last-child {
            padding-right: 15px; } } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
        border-top: 1px solid rgba(#000000, 0.08) !important;
        &:first-child {
          border-top: none !important; } } }
    [data-label] {
      &::before {
        display: block; } } } }
.table-responsive--sm {
  @media (max-width: 767px) {
    thead {
      display: none; }
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: f7f7f7; }
        td {
          padding-right: 15px;
          &:last-child {
            padding-right: 15px; } } } }
    tr {
      th, td {
        display: block;
        padding-left: 45%!important;
        text-align: right!important;
        border-top: 1px solid rgba(#000000, 0.08) !important;
        &:first-child {
          border-top: none !important; } } }
    [data-label] {
      &::before {
        display: block; } } } }
*[class*="table-responsive--"] {
  &.data-label--none {
    @media (max-width: 1199px) {
      tr {
        th, td {
          padding-left: .75rem; } } } } }
/* table css end */
