/* account section css start */
.account-wrapper {
  padding: rem(25px);
  @extend %bg--white;
  @extend %bs-8;
  box-shadow: 0 5px 25px rgba(#000, 0.15); }

.account-thumb {
  padding: rem(50px);
  @include media(575px) {
    padding: rem(30px); } }

.account-content {
  padding: rem(25px);
  @include media(991px) {
    padding: rem(10px) 0; } }
/* account section css end */
