@import "./_reset";
/* global css strat */
@import "./_color";
@import "./_bg";

@media (min-width: 1650px) {
  .xxxl-2 {
    width: 16.666%; }
  .xxxl-8 {
    width: 66.668%; } }
// padding global css
.pt-50 {
  padding-top: 50px; }
.pb-50 {
  padding-bottom: 50px; }
.pt-80 {
  padding-top: 80px; }
.pb-80 {
  padding-bottom: 80px; }
.pt-100 {
  padding-top: 100px;
  @include media(767px) {
    padding-top: 80px; } }
.pb-100 {
  padding-bottom: 100px;
  @include media(767px) {
    padding-bottom: 80px; } }
.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.section--bg {
  background-color: #fafafb; }
.section--bg2 {
  background-color: $base-color-two; }
.section-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.white--overlay {
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: #fff;
    opacity: 0.7;
    z-index: -1; } }

.dark--overlay {
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.4;
    z-index: -1; } }

.dark--overlay-two {
  position: relative;
  z-index: 1;
  &::before {
    @include positionTwo;
    background-color: $base-color-two;
    opacity: 0.8;
    z-index: -1; } }
.bg--one {
  background-color: $bg-one; }
.bg--gradient {
  @include grad-one; }
.slick-arrow {
  cursor: pointer; }
.z-index-2 {
  z-index: 2; }
.main-wrapper {
  position: relative; }
.section-header {
  margin-bottom: rem(45px);
  @include media(767px) {
    margin-bottom: rem(30px); } }
.section-title {
  font-size: rem(36px);
  font-weight: 600;
  @include media(767px) {
    font-size: rem(32px); }
  @include media(575px) {
    font-size: rem(28px); } }
.section-subtitle {
  font-family: $heading-font;
  font-size: rem(16px);
  font-weight: 500;
  &.border-left {
    padding-left: rem(35px);
    position: relative;
    z-index: 1;
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 25px;
      height: 2px;
      background-color: $base-color;
      margin-top: -1px; } } }
a.text-white {
  &:hover {
    color: $base-color !important; } }
.text--link {
  text-decoration: underline;
  &:hover {
    text-decoration: underline; } }
.has--link {
  position: relative;
  .item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; } }
.custom--dropdown {
  .dropdown-toggle {
    &.no-arrow {
      &::after {
        display: none; } }
    &::after {
      content: "\f107";
      border: none;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      @include transform(translateY(3px)); } }
  .dropdown-menu {
    border-color: #e5e5e5;
    box-shadow: 0 5px 10px rgba(#000, 0.05);
    li {
      border-bottom: 1px dashed #e5e5e5;
      &:last-child {
        border-bottom: none; }
      .dropdown-item {
        color: $para-color;
        font-size: rem(14px);
        &:hover {
          color: $base-color;
          background-color: rgba($base-color, 0.05); } } } } }
.custom--nav-tabs {
  border-bottom: none;
  .nav-item {
    .nav-link {
      background-color: #fff;
      border: none;
      @include border-radius(0px);
      padding: rem(12px) rem(25px);
      &.active {
        background-color: $base-color;
        color: #fff; } } } }
.custom--accordion {
  .accordion-item + .accordion-item {
    margin-top: rem(20px); }
  .accordion-item {
    border: 1px solid rgba($base-color, 0.5);
    @include border-radius(5px);
    &:first-child {
      .accordion-button {
        border-top: none; } }
    &:last-child {
      .accordion-button {
        border-bottom: none; } } }
  .accordion-button {
    padding: rem(20px) rem(25px);
    background-color: rgba($base-color, 0.05);
    font-size: rem(18px);
    position: relative;
    text-align: left;
    &::after {
      position: absolute;
      top: rem(20px);
      right: rem(13px);
      font-size: rem(17px);
      content: '\f107';
      font-family: 'Line Awesome Free';
      font-weight: 900;
      background-image: none;
      color: #000; }
    &:not(.collapsed) {
      background-color: $base-color;
      color: #fff;
      &::after {
        color: #fff; } }
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: transparent; } }
  .accordion-body {
    padding: rem(20px) rem(25px); } }
.custom--accordion-two {
  .accordion-button {
    background-color: #f3f6f9;
    &:focus {
      box-shadow: none;
      border-color: rgba(0,0,0,.125); }
    &:not(.collapsed) {
      background-color: #f3f6f9;
      @extend %text--base; } } }
.cmn-list {
  li + li {
    margin-top: rem(15px); }
  li {
    position: relative;
    padding-left: rem(35px);
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      content: "\f058";
      font-size: rem(28px);
      color: $base-color;
      margin-right: rem(8px);
      line-height: 1; } } }
.number-list {
  list-style: decimal;
  padding-left: rem(18px);
  li + li {
    margin-top: rem(10px); } }

.disc-list {
  li + li {
    margin-top: rem(10px); }
  li {
    position: relative;
    padding-left: rem(15px);
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: rem(6px);
      height: rem(6px);
      margin-top: rem(-3px);
      @include border-radius(50%);
      background-color: #575757; } } }
.square-list {
  li + li {
    margin-top: 10px; }
  li {
    padding-left: 25px;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 0;
      width: 14px;
      height: 14px;
      background-color: #e6e6e6; }
    &::after {
      position: absolute;
      content: '';
      top: 9px;
      left: 4px;
      width: 14px;
      height: 14px;
      background-color: rgba($base-color, 0.45); } } }

.caption-list {
  li {
    display: flex;
    flex-wrap: wrap;
    padding: rem(10px) 0;
    font-size: rem(15px);
    border-bottom: 1px dashed $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption {
      width: 40%;
      font-family: $heading-font;
      font-weight: 700;
      font-size: rem(14px);
      position: relative;
      &::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; } }
    .value {
      width: 60%;
      padding-left: rem(15px); } } }
.caption-list-two {
  padding: rem(10px) rem(15px);
  background-color: rgba($base-color, 0.1);
  @include border-radius(5px);
  li {
    font-family: $heading-font;
    font-weight: 500;
    color: $heading-color;
    font-size: rem(14px);
    padding: rem(8px) 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px dashed darken($border-color, 10%);
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption {
      width: 20%;
      position: relative;
      font-weight: 700;
      padding-right: 10px;
      &::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; } }
    .value {
      width: 80%;
      padding-left: 20px; } } }
%transition {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
%base-color {
  background-color: $base-color; }
%base-color-two {
  background-color: $base-color-two; }
%text--base {
  color: $base-color; }
%bg--white {
  background-color: #fff; }
%text--white {
  color: #fff; }
%obj-fit {
  @include object-fit; }
%d-flex {
  @include d-flex; }

%bs-5 {
  @include border-radius(5px); }
%bs-8 {
  @include border-radius(8px); }

.object-fit--cover {
  @include object-fit; }
.pagination {
  margin: rem(-5px) rem(-7px);
  flex-wrap: wrap;
  .page-item {
    margin: rem(5px) rem(7px);
    &.active {
      .page-link {
        background-color: $base-color;
        color: #fff; } }
    .page-link {
      width: rem(45px);
      height: rem(45px);
      display: flex;
      justify-content: center;
      align-items: center;
      @include border-radius(5px);
      border: 1px solid rgba($base-color, 0.25);
      color: $para-color;
      &:hover {
        background-color: $base-color;
        border-color: $base-color;
        color: #fff; } } } }
.pagination-md {
  .page-item {
    .page-link {
      width: rem(40px);
      height: rem(40px); } } }
.pagination-sm {
  .page-item {
    .page-link {
      width: rem(35px);
      height: rem(35px);
      font-size: rem(14px); } } }
// animation css
.shake {
  animation: shake 0.5s 1 linear; }
@include keyframes (shake) {
  0%, 100% {
    @include transform(translateX(0)); }
  10%, 30%, 50%, 70%, 90% {
    @include transform(translateX(-10px)); }
  20%, 40%, 60%, 80% {
    @include transform(translateX(10px)); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }
@include keyframes (fadeInUp) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }
@include keyframes (fadeInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }
@include keyframes (fadeInRight) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }
.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }
@include keyframes(slideInLeft) {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }
.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }
@include keyframes(slideInRight) {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.scroll-to-top {
  height: 45px;
  width: 45px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  @extend %base-color-two;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  .scroll-icon {
    font-size: rem(20px);
    color: #ffffff;
    display: inline-block; } }

.preloader-holder {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  @extend %base-color-two;
  z-index: 999999; }

.preloader {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in;

  div {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0%;
      width: 10%;
      height: 10%;
      background-color: #ffffff;
      transform: translateX(-50%);
      border-radius: 50%; }

    &:nth-child(1) {
      transform: rotateZ(0deg);
      animation: rotateCircle1 2s infinite linear;
      z-index: 9; }

    &:nth-child(2) {
      transform: rotateZ(36deg);
      animation: rotateCircle2 2s infinite linear;
      z-index: 8; }

    &:nth-child(3) {
      transform: rotateZ(72deg);
      animation: rotateCircle3 2s infinite linear;
      z-index: 7; }

    &:nth-child(4) {
      transform: rotateZ(108deg);
      animation: rotateCircle4 2s infinite linear;
      z-index: 6; }

    &:nth-child(5) {
      transform: rotateZ(144deg);
      animation: rotateCircle5 2s infinite linear;
      z-index: 5; }

    &:nth-child(6) {
      transform: rotateZ(180deg);
      animation: rotateCircle6 2s infinite linear;
      z-index: 4; }

    &:nth-child(7) {
      transform: rotateZ(216deg);
      animation: rotateCircle7 2s infinite linear;
      z-index: 3; }

    &:nth-child(8) {
      transform: rotateZ(252deg);
      animation: rotateCircle8 2s infinite linear;
      z-index: 2; }

    &:nth-child(9) {
      transform: rotateZ(288deg);
      animation: rotateCircle9 2s infinite linear;
      z-index: 1; }

    &:nth-child(10) {
      transform: rotateZ(324deg);
      animation: rotateCircle10 2s infinite linear;
      z-index: 0; } } }

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg); }

  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg); } }

@keyframes rotateCircle1 {
  0% {
    opacity: 0; }

  0% {
    opacity: 1;
    transform: rotateZ(36deg); }

  7% {
    transform: rotateZ(0deg); }

  57% {
    transform: rotateZ(0deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle2 {
  5% {
    opacity: 0; }

  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg); }

  12% {
    transform: rotateZ(-36deg); }

  62% {
    transform: rotateZ(-36deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle3 {
  10% {
    opacity: 0; }

  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg); }

  17% {
    transform: rotateZ(-72deg); }

  67% {
    transform: rotateZ(-72deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle4 {
  15% {
    opacity: 0; }

  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg); }

  22% {
    transform: rotateZ(-108deg); }

  72% {
    transform: rotateZ(-108deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle5 {
  20% {
    opacity: 0; }

  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg); }

  27% {
    transform: rotateZ(-144deg); }

  77% {
    transform: rotateZ(-144deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle6 {
  25% {
    opacity: 0; }

  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg); }

  32% {
    transform: rotateZ(-180deg); }

  82% {
    transform: rotateZ(-180deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle7 {
  30% {
    opacity: 0; }

  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg); }

  37% {
    transform: rotateZ(-216deg); }

  87% {
    transform: rotateZ(-216deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle8 {
  35% {
    opacity: 0; }

  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg); }

  42% {
    transform: rotateZ(-252deg); }

  92% {
    transform: rotateZ(-252deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle9 {
  40% {
    opacity: 0; }

  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg); }

  47% {
    transform: rotateZ(-288deg); }

  97% {
    transform: rotateZ(-288deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle10 {
  45% {
    opacity: 0; }

  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg); }

  52% {
    transform: rotateZ(-324deg); }

  102% {
    transform: rotateZ(-324deg); }

  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }
/* global css end */
