/* form css start */
.form-group {
  margin-bottom: rem(15px); }
.form--control {
  padding: rem(10px) rem(20px);
  border: 1px solid $base-color;
  width: 100%;
  background-color: #fff;
  @include border-radius(3px);
  color: #000;
  height: rem(50px);
  &:focus {
    background-color: #fff;
    border-color: $base-color !important;
    box-shadow: 0 0 5px rgba($base-color, 0.35);
    color: #000; }
  &:placeholder-shown {
    border-color: darken($border-color, 5%);
    color: $para-color; }
  &[readonly] {
    background-color: #fff; }
  &.style--two {
    border-width: 0 0 1px 0;
    padding: 0 0 rem(10px) 0;
    @include border-radius(0);
    font-family: $heading-font;
    border-bottom-color: lighten(#000, 60%);
    &:focus {
      box-shadow: none; } }
  &.form-control-md {
    height: 45px; }
  &.form-control-sm {
    height: 35px; } }

.custom-icon-field {
  position: relative;
  i {
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    left: rem(12px); }
  i[class*="la-"] {
    font-size: rem(20px); }
  .form--control {
    padding-left: rem(40px); } }

.select {
  padding: rem(10px) rem(20px);
  width: 100%;
  border: 1px solid darken($border-color, 5%);
  cursor: pointer;
  color: $para-color;
  background-color: #fff;
  height: rem(50px);
  @include border-radius(4px);
  option {
    padding: rem(10px) 0;
    display: block;
    border-top: 1px solid #e5e5e5; }
  &.style--trans {
    background-color: transparent;
    color: #fff;
    border-color: rgba(#fff, 0.5);
    option {
      color: #363636; } }
  &.select-sm {
    height: rem(35px);
    font-size: rem(14px);
    padding: rem(5px); } }
textarea {
  min-height: rem(150px) !important;
  resize: none;
  width: 100%; }
label {
  color: lighten($para-color, 5%);
  margin-bottom: rem(10px);
  font-size: rem(14px);
  font-weight: 500; }
.input-group>.form--control,
.input-group>.select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }
.input-group {
  select {
    background-color: transparent;
    border: none; } }

.custom--radio {
  position: relative;
  padding-left: 0;
  input[type=radio] {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    cursor: pointer;
    &:checked {
      ~ label {
        &::before {
          border-width: 2px;
          border-color: $base-color; }
        &::after {
          opacity: 1; } } } }
  label {
    margin-bottom: 0;
    position: relative;
    padding-left: 20px;
    font-size: rem(14px);
    font-weight: 400;
    &::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #888888;
      @include border-radius(50%);
      @include transition(all 0.3s); }
    &::after {
      position: absolute;
      content: '';
      top: 8px;
      left: 4px;
      width: 7px;
      height: 7px;
      background-color: $base-color;
      @include border-radius(50%);
      opacity: 0;
      @include transition(all 0.3s); } } }

.custom--checkbox {
  padding-left: rem(25px);
  input {
    display: none;
    &:checked {
      ~ label {
        &::before {
          content: "\f14a";
          color: $base-color; } } } }
  label {
    position: relative;
    font-size: rem(15px);
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0;
    &::before {
      position: absolute;
      content: "\f04d";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 3px;
      left: rem(-25px);
      font-size: rem(20px);
      line-height: 1;
      @extend %transition; } } }

.wrong-info {
  .form--control {
    border-color: $danger !important;
    box-shadow: 0 0 6px 1px rgba($danger, 0.3) !important; } }
.select2-basic {
  & ~ .select2-container {
    display: block;
    width: 100% !important;
    max-width: 100% !important;
    height: 50px; } }
.select2-container {
  .select2-dropdown {
    border: none;
    box-shadow: 0 5px 15px rgba(#000, 0.1); }
  .selection, .select2-selection--single, .select2-selection__rendered {
    display: block; }
  .select2-selection--single {
    height: 50px;
    border-color: darken($border-color, 5%);
    .select2-selection__rendered {
      line-height: 50px;
      padding-left: rem(20px); }
    .select2-selection__arrow {
      height: 50px; } } }
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $base-color; }
.select2-dropdown ::-webkit-scrollbar {
  width: rem(8px); }
.select2-dropdown ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
.select2-dropdown ::-webkit-scrollbar-thumb {
  background-color: $base-color-two; }

/* form css end*/
