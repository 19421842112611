/* reset css start */
@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&family=Roboto:wght@400;500;600&display=swap");
html {
  scroll-behavior: smooth; }

body {
  font-family: "Roboto", sans-serif;
  color: #565656;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  overflow-x: hidden; }
  body::-webkit-scrollbar {
    width: 8px; }
  body::-webkit-scrollbar-track {
    background: #f2f2f2; }
  body::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 2px solid #f2f2f2; }

@-webkit-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-ms-keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinRoll {
  from {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg); } }

img {
  max-width: 100%;
  height: auto;
  user-select: none; }

select {
  cursor: pointer; }

ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }

button {
  cursor: pointer; }

*:focus {
  outline: none; }

button {
  border: none; }

button:focus {
  outline: none; }

span {
  display: inline-block; }

a:hover {
  color: #4380e4; }

hr {
  background-color: #e0e0e0;
  opacity: 0.65; }

/* reset css end */
/* global css strat */
.text--primary {
  color: #7367f0 !important; }

.text--secondary {
  color: #868e96 !important; }

.text--success {
  color: #28c76f !important; }

.text--danger {
  color: #ea5455 !important; }

.text--warning {
  color: #ff9f43 !important; }

.text--info {
  color: #1e9ff2 !important; }

.text--dark {
  color: #10163A !important; }

.text--muted {
  color: #a0a0a0 !important; }

.text--base {
  color: #4380e4 !important; }

.text--dark {
  color: #373e4a !important; }

/* background color css start */
.bg--primary {
  background-color: #7367f0 !important; }

.bg--secondary {
  background-color: #868e96 !important; }

.bg--success {
  background-color: #28c76f !important; }

.bg--danger {
  background-color: #ea5455 !important; }

.bg--warning {
  background-color: #ff9f43 !important; }

.bg--info {
  background-color: #1e9ff2 !important; }

.bg--dark {
  background-color: #10163A !important; }

.bg--light {
  background-color: #bcc7da !important; }

.bg--base {
  background-color: #4380e4 !important; }

/* background color css end */
@media (min-width: 1650px) {
  .xxxl-2 {
    width: 16.666%; }
  .xxxl-8 {
    width: 66.668%; } }

.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.pt-80 {
  padding-top: 80px; }

.pb-80 {
  padding-bottom: 80px; }

.pt-100 {
  padding-top: 100px; }
  @media (max-width: 767px) {
    .pt-100 {
      padding-top: 80px; } }

.pb-100 {
  padding-bottom: 100px; }
  @media (max-width: 767px) {
    .pb-100 {
      padding-bottom: 80px; } }

.bg_img {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.section--bg {
  background-color: #fafafb; }

.section--bg2 {
  background-color: #2A3042; }

.section-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.white--overlay {
  position: relative;
  z-index: 1; }
  .white--overlay::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.7;
    z-index: -1; }

.dark--overlay {
  position: relative;
  z-index: 1; }
  .dark--overlay::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2A3042;
    opacity: 0.4;
    z-index: -1; }

.dark--overlay-two {
  position: relative;
  z-index: 1; }
  .dark--overlay-two::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2A3042;
    opacity: 0.8;
    z-index: -1; }

.bg--one {
  background-color: #071e3e; }

.bg--gradient {
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%); }

.slick-arrow {
  cursor: pointer; }

.z-index-2 {
  z-index: 2; }

.main-wrapper {
  position: relative; }

.section-header {
  margin-bottom: 2.8125rem; }
  @media (max-width: 767px) {
    .section-header {
      margin-bottom: 1.875rem; } }

.section-title {
  font-size: 2.25rem;
  font-weight: 600; }
  @media (max-width: 767px) {
    .section-title {
      font-size: 2rem; } }
  @media (max-width: 575px) {
    .section-title {
      font-size: 1.75rem; } }

.section-subtitle {
  font-family: "Exo 2", sans-serif;
  font-size: 1rem;
  font-weight: 500; }
  .section-subtitle.border-left {
    padding-left: 2.1875rem;
    position: relative;
    z-index: 1; }
    .section-subtitle.border-left::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 25px;
      height: 2px;
      background-color: #4380e4;
      margin-top: -1px; }

a.text-white:hover {
  color: #4380e4 !important; }

.text--link {
  text-decoration: underline; }
  .text--link:hover {
    text-decoration: underline; }

.has--link {
  position: relative; }
  .has--link .item--link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.custom--dropdown .dropdown-toggle.no-arrow::after {
  display: none; }

.custom--dropdown .dropdown-toggle::after {
  content: "\f107";
  border: none;
  font-family: 'Line Awesome Free';
  font-weight: 900;
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px); }

.custom--dropdown .dropdown-menu {
  border-color: #e5e5e5;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  .custom--dropdown .dropdown-menu li {
    border-bottom: 1px dashed #e5e5e5; }
    .custom--dropdown .dropdown-menu li:last-child {
      border-bottom: none; }
    .custom--dropdown .dropdown-menu li .dropdown-item {
      color: #565656;
      font-size: 0.875rem; }
      .custom--dropdown .dropdown-menu li .dropdown-item:hover {
        color: #4380e4;
        background-color: rgba(67, 128, 228, 0.05); }

.custom--nav-tabs {
  border-bottom: none; }
  .custom--nav-tabs .nav-item .nav-link {
    background-color: #fff;
    border: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    padding: 0.75rem 1.5625rem; }
    .custom--nav-tabs .nav-item .nav-link.active {
      background-color: #4380e4;
      color: #fff; }

.custom--accordion .accordion-item + .accordion-item {
  margin-top: 1.25rem; }

.custom--accordion .accordion-item {
  border: 1px solid rgba(67, 128, 228, 0.5);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .custom--accordion .accordion-item:first-child .accordion-button {
    border-top: none; }
  .custom--accordion .accordion-item:last-child .accordion-button {
    border-bottom: none; }

.custom--accordion .accordion-button {
  padding: 1.25rem 1.5625rem;
  background-color: rgba(67, 128, 228, 0.05);
  font-size: 1.125rem;
  position: relative;
  text-align: left; }
  .custom--accordion .accordion-button::after {
    position: absolute;
    top: 1.25rem;
    right: 0.8125rem;
    font-size: 1.0625rem;
    content: '\f107';
    font-family: 'Line Awesome Free';
    font-weight: 900;
    background-image: none;
    color: #000; }
  .custom--accordion .accordion-button:not(.collapsed) {
    background-color: #4380e4;
    color: #fff; }
    .custom--accordion .accordion-button:not(.collapsed)::after {
      color: #fff; }
  .custom--accordion .accordion-button:focus {
    box-shadow: none;
    outline: none;
    border-color: transparent; }

.custom--accordion .accordion-body {
  padding: 1.25rem 1.5625rem; }

.custom--accordion-two .accordion-button {
  background-color: #f3f6f9; }
  .custom--accordion-two .accordion-button:focus {
    box-shadow: none;
    border-color: rgba(0, 0, 0, 0.125); }
  .custom--accordion-two .accordion-button:not(.collapsed) {
    background-color: #f3f6f9; }

.cmn-list li + li {
  margin-top: 0.9375rem; }

.cmn-list li {
  position: relative;
  padding-left: 2.1875rem; }
  .cmn-list li::before {
    position: absolute;
    top: 0;
    left: 0;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    content: "\f058";
    font-size: 1.75rem;
    color: #4380e4;
    margin-right: 0.5rem;
    line-height: 1; }

.number-list {
  list-style: decimal;
  padding-left: 1.125rem; }
  .number-list li + li {
    margin-top: 0.625rem; }

.disc-list li + li {
  margin-top: 0.625rem; }

.disc-list li {
  position: relative;
  padding-left: 0.9375rem; }
  .disc-list li::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 0.375rem;
    height: 0.375rem;
    margin-top: -0.1875rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    background-color: #575757; }

.square-list li + li {
  margin-top: 10px; }

.square-list li {
  padding-left: 25px;
  position: relative; }
  .square-list li::before {
    position: absolute;
    content: '';
    top: 5px;
    left: 0;
    width: 14px;
    height: 14px;
    background-color: #e6e6e6; }
  .square-list li::after {
    position: absolute;
    content: '';
    top: 9px;
    left: 4px;
    width: 14px;
    height: 14px;
    background-color: rgba(67, 128, 228, 0.45); }

.caption-list li {
  display: flex;
  flex-wrap: wrap;
  padding: 0.625rem 0;
  font-size: 0.9375rem;
  border-bottom: 1px dashed #e0e0e0; }
  .caption-list li:first-child {
    padding-top: 0; }
  .caption-list li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .caption-list li .caption {
    width: 40%;
    font-family: "Exo 2", sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
    position: relative; }
    .caption-list li .caption::after {
      position: absolute;
      content: ':';
      top: 0;
      right: 0; }
  .caption-list li .value {
    width: 60%;
    padding-left: 0.9375rem; }

.caption-list-two {
  padding: 0.625rem 0.9375rem;
  background-color: rgba(67, 128, 228, 0.1);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }
  .caption-list-two li {
    font-family: "Exo 2", sans-serif;
    font-weight: 500;
    color: #373e4a;
    font-size: 0.875rem;
    padding: 0.5rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px dashed #c7c7c7; }
    .caption-list-two li:first-child {
      padding-top: 0; }
    .caption-list-two li:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    .caption-list-two li .caption {
      width: 20%;
      position: relative;
      font-weight: 700;
      padding-right: 10px; }
      .caption-list-two li .caption::after {
        position: absolute;
        content: ':';
        top: 0;
        right: 0; }
    .caption-list-two li .value {
      width: 80%;
      padding-left: 20px; }

body, .custom--checkbox label::before, .header .main-menu li.menu_has_children > a::before, .header .main-menu li .sub-menu, .header .main-menu li .sub-menu li a, .header-search-form.header-search-form-mobile, .header-search-open-btn i::before, .d-widget, .d-widget__icon, .user-menu li a {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }

body::-webkit-scrollbar-thumb, .post-filter-list li a::after, .single-post .forum-badge, .post-details__badge, .d-widget:hover .d-widget__icon, .d-widget__btn, .user-menu li.active a, .user-menu li.active:hover a {
  background-color: #4380e4; }

.scroll-to-top, .preloader-holder, .custom--table thead, .header__bottom, .header-search-form__input, .header-search-form.header-search-form-mobile, .forum-block__header, .user-widget, .sidebar-widget__header, .footer-section, .conatact-section::after {
  background-color: #2A3042; }

.custom--accordion-two .accordion-button:not(.collapsed), .header .site-logo.site-title, .sub-forum-list li a, .d-widget__icon, .user-info-list li i, .user-menu li:hover a, .category-list li:hover a, .category-list li.active a, .social-link li a:hover i, .contact-item i, .contact-item a:hover {
  color: #4380e4; }

.account-wrapper, .contact-wrapper {
  background-color: #fff; }

.d-widget__btn {
  color: #fff; }

.single-thread__right .top__list-user li img, .single-thread__right .latest-topic__thumb img, .post-author__thumb img, .comment-wrapper__thumb img, .single-comment__thumb img, .contributor-list .single-contributor__thumb img, .unanswered-list .single-unanswered__thumb img, .topic-list .single-topic__thumb img {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center; }

.single-thread, .single-thread__right .top__list, .single-thread__right .latest-topic, .post-filter-list, .single-post, .single-post__action-list .left,
.single-post__action-list .right, .post-details__tags, .post-author, .comment-wrapper, .single-comment, .statistics-list, .topic-list .single-topic {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.single-thread, .post-filter-area, .d-widget__icon, .user-widget .thumb, .user-menu li.active a, .user-menu li:hover a {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px; }

.custom--card, .forum-block, .single-post, .d-widget, .user-widget, .user-menu-widget, .sidebar-widget, .account-wrapper, .contact-wrapper, .contact-item {
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; }

.object-fit--cover {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center; }

.pagination {
  margin: -0.3125rem -0.4375rem;
  flex-wrap: wrap; }
  .pagination .page-item {
    margin: 0.3125rem 0.4375rem; }
    .pagination .page-item.active .page-link {
      background-color: #4380e4;
      color: #fff; }
    .pagination .page-item .page-link {
      width: 2.8125rem;
      height: 2.8125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border: 1px solid rgba(67, 128, 228, 0.25);
      color: #565656; }
      .pagination .page-item .page-link:hover {
        background-color: #4380e4;
        border-color: #4380e4;
        color: #fff; }

.pagination-md .page-item .page-link {
  width: 2.5rem;
  height: 2.5rem; }

.pagination-sm .page-item .page-link {
  width: 2.1875rem;
  height: 2.1875rem;
  font-size: 0.875rem; }

.shake {
  animation: shake 0.5s 1 linear; }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@-moz-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@-ms-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px); } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight; }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft; }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-ms-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-moz-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-ms-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.scroll-to-top {
  height: 45px;
  width: 45px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25); }
  .scroll-to-top .scroll-icon {
    font-size: 1.25rem;
    color: #ffffff;
    display: inline-block; }

.preloader-holder {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999; }

.preloader {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation: rotatePreloader 2s infinite ease-in; }
  .preloader div {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0; }
    .preloader div:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0%;
      width: 10%;
      height: 10%;
      background-color: #ffffff;
      transform: translateX(-50%);
      border-radius: 50%; }
    .preloader div:nth-child(1) {
      transform: rotateZ(0deg);
      animation: rotateCircle1 2s infinite linear;
      z-index: 9; }
    .preloader div:nth-child(2) {
      transform: rotateZ(36deg);
      animation: rotateCircle2 2s infinite linear;
      z-index: 8; }
    .preloader div:nth-child(3) {
      transform: rotateZ(72deg);
      animation: rotateCircle3 2s infinite linear;
      z-index: 7; }
    .preloader div:nth-child(4) {
      transform: rotateZ(108deg);
      animation: rotateCircle4 2s infinite linear;
      z-index: 6; }
    .preloader div:nth-child(5) {
      transform: rotateZ(144deg);
      animation: rotateCircle5 2s infinite linear;
      z-index: 5; }
    .preloader div:nth-child(6) {
      transform: rotateZ(180deg);
      animation: rotateCircle6 2s infinite linear;
      z-index: 4; }
    .preloader div:nth-child(7) {
      transform: rotateZ(216deg);
      animation: rotateCircle7 2s infinite linear;
      z-index: 3; }
    .preloader div:nth-child(8) {
      transform: rotateZ(252deg);
      animation: rotateCircle8 2s infinite linear;
      z-index: 2; }
    .preloader div:nth-child(9) {
      transform: rotateZ(288deg);
      animation: rotateCircle9 2s infinite linear;
      z-index: 1; }
    .preloader div:nth-child(10) {
      transform: rotateZ(324deg);
      animation: rotateCircle10 2s infinite linear;
      z-index: 0; }

@keyframes rotatePreloader {
  0% {
    transform: translateX(-50%) translateY(-50%) rotateZ(0deg); }
  100% {
    transform: translateX(-50%) translateY(-50%) rotateZ(-360deg); } }

@keyframes rotateCircle1 {
  0% {
    opacity: 0; }
  0% {
    opacity: 1;
    transform: rotateZ(36deg); }
  7% {
    transform: rotateZ(0deg); }
  57% {
    transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle2 {
  5% {
    opacity: 0; }
  5.0001% {
    opacity: 1;
    transform: rotateZ(0deg); }
  12% {
    transform: rotateZ(-36deg); }
  62% {
    transform: rotateZ(-36deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle3 {
  10% {
    opacity: 0; }
  10.0002% {
    opacity: 1;
    transform: rotateZ(-36deg); }
  17% {
    transform: rotateZ(-72deg); }
  67% {
    transform: rotateZ(-72deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle4 {
  15% {
    opacity: 0; }
  15.0003% {
    opacity: 1;
    transform: rotateZ(-72deg); }
  22% {
    transform: rotateZ(-108deg); }
  72% {
    transform: rotateZ(-108deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle5 {
  20% {
    opacity: 0; }
  20.0004% {
    opacity: 1;
    transform: rotateZ(-108deg); }
  27% {
    transform: rotateZ(-144deg); }
  77% {
    transform: rotateZ(-144deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle6 {
  25% {
    opacity: 0; }
  25.0005% {
    opacity: 1;
    transform: rotateZ(-144deg); }
  32% {
    transform: rotateZ(-180deg); }
  82% {
    transform: rotateZ(-180deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle7 {
  30% {
    opacity: 0; }
  30.0006% {
    opacity: 1;
    transform: rotateZ(-180deg); }
  37% {
    transform: rotateZ(-216deg); }
  87% {
    transform: rotateZ(-216deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle8 {
  35% {
    opacity: 0; }
  35.0007% {
    opacity: 1;
    transform: rotateZ(-216deg); }
  42% {
    transform: rotateZ(-252deg); }
  92% {
    transform: rotateZ(-252deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle9 {
  40% {
    opacity: 0; }
  40.0008% {
    opacity: 1;
    transform: rotateZ(-252deg); }
  47% {
    transform: rotateZ(-288deg); }
  97% {
    transform: rotateZ(-288deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

@keyframes rotateCircle10 {
  45% {
    opacity: 0; }
  45.0009% {
    opacity: 1;
    transform: rotateZ(-288deg); }
  52% {
    transform: rotateZ(-324deg); }
  102% {
    transform: rotateZ(-324deg); }
  100% {
    transform: rotateZ(-324deg);
    opacity: 1; } }

/* global css end */
h1 {
  font-size: 3.875rem; }

h2 {
  font-size: 2rem; }
  @media (max-width: 991px) {
    h2 {
      font-size: 1.875rem; } }
  @media (max-width: 575px) {
    h2 {
      font-size: 1.75rem; } }

h3 {
  font-size: 1.5rem; }

h4 {
  font-size: 1.375rem;
  line-height: 1.5; }
  @media (max-width: 767px) {
    h4 {
      font-size: 1.25rem; } }

h5 {
  font-size: 1.25rem;
  line-height: 1.5; }
  @media (max-width: 767px) {
    h5 {
      font-size: 1.125rem; } }

h6 {
  font-size: 1.125rem; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Exo 2", sans-serif;
  color: #373e4a;
  font-weight: 600;
  margin: 0;
  line-height: 1.4;
  word-break: break-word; }

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  font-family: "Exo 2", sans-serif;
  color: #373e4a;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 1.4;
  word-break: break-word; }

p, li, span {
  margin: 0; }

a {
  text-decoration: none;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 400; }

a:hover {
  text-decoration: none; }

.fs--18px {
  font-size: 1.125rem !important; }

.fs--16px {
  font-size: 1rem !important; }

.fs--14px {
  font-size: 0.875rem !important; }

.fs--12px {
  font-size: 0.75rem !important; }

.h--font {
  font-family: "Exo 2", sans-serif !important; }

.p--font {
  font-family: "Roboto", sans-serif !important; }

.fw-medium {
  font-weight: 500 !important; }

/* button css start */
button:focus {
  outline: none; }

[class*="btn--"]:not(.btn--link):not(.btn--light) {
  color: #fff; }

.btn {
  padding: 0.75rem 1.875rem; }

.btn--primary {
  background-color: #7367f0; }
  .btn--primary:hover {
    background-color: #5e50ee; }

.btn--secondary {
  background-color: #868e96; }
  .btn--secondary:hover {
    background-color: #78818a; }

.btn--success {
  background-color: #28c76f; }
  .btn--success:hover {
    background-color: #24b263; }

.btn--danger {
  background-color: #ea5455; }
  .btn--danger:hover {
    background-color: #e73d3e; }

.btn--warning {
  background-color: #ff9f43; }
  .btn--warning:hover {
    background-color: #ff922a; }

.btn--info {
  background-color: #1e9ff2; }
  .btn--info:hover {
    background-color: #0d93e9; }

.btn--light {
  background-color: #bcc7da; }
  .btn--light:hover {
    background-color: #acb9d1; }

.btn--dark {
  background-color: #10163A;
  color: #fff; }
  .btn--dark:hover {
    background-color: #0a0e26;
    color: #fff; }

.btn--link {
  color: #7367f0; }

.btn--base {
  background-color: #4380e4;
  color: #fff; }
  .btn--base:hover {
    background-color: #2d71e1;
    color: #fff; }

.btn--gradient {
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 100%);
  border: none; }

.text--btn {
  color: #4380e4; }

.btn-outline--primary {
  color: #7367f0;
  border-color: #7367f0; }
  .btn-outline--primary:hover {
    background-color: #7367f0;
    color: #ffffff; }

.btn-outline--secondary {
  color: #868e96;
  border-color: #868e96; }
  .btn-outline--secondary:hover {
    background-color: #868e96;
    color: #ffffff; }

.btn-outline--success {
  color: #28c76f;
  border-color: #28c76f; }
  .btn-outline--success:hover {
    background-color: #28c76f;
    color: #ffffff; }

.btn-outline--danger {
  color: #ea5455;
  border-color: #ea5455; }
  .btn-outline--danger:hover {
    background-color: #ea5455;
    color: #ffffff; }

.btn-outline--warning {
  color: #ff9f43;
  border-color: #ff9f43; }
  .btn-outline--warning:hover {
    background-color: #ff9f43;
    color: #ffffff; }

.btn-outline--info {
  color: #1e9ff2;
  border-color: #1e9ff2; }
  .btn-outline--info:hover {
    background-color: #1e9ff2;
    color: #ffffff; }

.btn-outline--light {
  color: #bcc7da;
  border-color: #bcc7da; }
  .btn-outline--light:hover {
    background-color: #bcc7da;
    color: #ffffff; }

.btn-outline--dark {
  color: #10163A;
  border-color: #10163A; }
  .btn-outline--dark:hover {
    background-color: #10163A;
    color: #ffffff; }

.btn-outline--base {
  color: #4380e4;
  border: 1px solid #4380e4; }
  .btn-outline--base:hover {
    background-color: #4380e4;
    color: #fff; }

.btn-shadow--primary {
  box-shadow: 0 0 6px 1px rgba(115, 103, 240, 0.35); }

.btn-shadow--secondary {
  box-shadow: 0 0 6px 1px rgba(134, 142, 150, 0.35); }

.btn-shadow--success {
  box-shadow: 0 0 6px 1px rgba(40, 199, 111, 0.35); }

.btn-shadow--danger {
  box-shadow: 0 0 6px 1px rgba(234, 84, 85, 0.35); }

.btn-shadow--warning {
  box-shadow: 0 0 6px 1px rgba(255, 159, 67, 0.35); }

.btn-shadow--info {
  box-shadow: 0 0 6px 1px rgba(30, 159, 242, 0.35); }

.btn-shadow--light {
  box-shadow: 0 0 6px 1px rgba(188, 199, 218, 0.35); }

.btn-shadow--dark {
  box-shadow: 0 0 6px 1px rgba(16, 22, 58, 0.35); }

.btn-shadow--base {
  box-shadow: 0 0 6px 1px rgba(67, 128, 228, 0.35); }

.btn--capsule {
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px; }

.icon-btn {
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #4380e4;
  color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center; }
  .icon-btn:hover {
    color: #fff; }

.btn--group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -0.625rem;
  margin-right: -0.625rem; }
  .btn--group *[class*="btn"] {
    margin: 0.3125rem 0.625rem;
    align-items: center; }
    .btn--group *[class*="btn"].d-flex {
      padding: 0.5rem 2.1875rem; }
  .btn--group.style--two {
    margin-left: -0.3125rem;
    margin-right: -0.3125rem; }
    .btn--group.style--two *[class*="btn"] {
      margin: 0.1875rem 0.3125rem; }

[class*="btn"].btn-md {
  padding: 0.625rem 1.25rem; }

[class*="btn"].btn-sm {
  padding: 0.375rem 0.625rem; }

/* button css end */
.badge--primary {
  background-color: rgba(115, 103, 240, 0.15);
  border: 1px solid #7367f0;
  color: #7367f0; }

.badge--secondary {
  background-color: rgba(134, 142, 150, 0.15);
  border: 1px solid #868e96;
  color: #868e96; }

.badge--success {
  background-color: rgba(40, 199, 111, 0.15);
  border: 1px solid #28c76f;
  color: #28c76f; }

.badge--danger {
  background-color: rgba(234, 84, 85, 0.15);
  border: 1px solid #ea5455;
  color: #ea5455; }

.badge--warning {
  background-color: rgba(255, 159, 67, 0.15);
  border: 1px solid #ff9f43;
  color: #ff9f43; }

.badge--info {
  background-color: rgba(30, 159, 242, 0.15);
  border: 1px solid #1e9ff2;
  color: #1e9ff2; }

.badge--light {
  background-color: rgba(188, 199, 218, 0.15);
  border: 1px solid #bcc7da;
  color: #bcc7da; }

.badge--dark {
  background-color: rgba(16, 22, 58, 0.15);
  border: 1px solid #10163A;
  color: #10163A; }

.badge--base {
  background-color: rgba(67, 128, 228, 0.15);
  border: 1px solid #4380e4;
  color: #4380e4; }

/* table css start */
.custom--table thead th {
  border-bottom: 1px solid #f0f1f5 !important;
  border-top: none;
  padding: 0.75rem 1.25rem;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600; }
  .custom--table thead th:first-child {
    text-align: left;
    border-radius: 8px 0 0 8px;
    -webkit-border-radius: 8px 0 0 8px;
    -moz-border-radius: 8px 0 0 8px;
    -ms-border-radius: 8px 0 0 8px;
    -o-border-radius: 8px 0 0 8px; }
  .custom--table thead th:last-child {
    text-align: right;
    border-radius: 0 8px 8px 0;
    -webkit-border-radius: 0 8px 8px 0;
    -moz-border-radius: 0 8px 8px 0;
    -ms-border-radius: 0 8px 8px 0;
    -o-border-radius: 0 8px 8px 0; }

.custom--table tbody td {
  border-top: none;
  border-bottom: 1px solid #f0f1f5;
  padding: 12px 20px;
  color: #7e7e7e;
  text-align: center; }
  .custom--table tbody td:first-child {
    text-align: left; }
  .custom--table tbody td:last-child {
    text-align: right; }

.custom--table tbody tr {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .custom--table tbody tr:hover {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    background-color: #ffffff; }
    .custom--table tbody tr:hover td {
      border-bottom-color: transparent; }
  .custom--table tbody tr:last-child td {
    border-bottom: none; }

[data-label] {
  position: relative; }
  [data-label]::before {
    position: absolute;
    content: attr(data-label);
    font-weight: 700;
    color: #000000;
    top: 0;
    left: 0;
    padding: 0.8125rem 0.9375rem;
    display: none;
    font-size: 0.75rem; }

@media (max-width: 991px) {
  .table-responsive--md thead {
    display: none; }
  .table-responsive--md tbody tr:nth-child(odd) {
    background-color: #f7f7f7; }
  .table-responsive--md tbody tr td {
    padding-right: 15px; }
    .table-responsive--md tbody tr td:last-child {
      padding-right: 15px; }
  .table-responsive--md tr th, .table-responsive--md tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important; }
    .table-responsive--md tr th:first-child, .table-responsive--md tr td:first-child {
      border-top: none !important; }
  .table-responsive--md [data-label]::before {
    display: block; } }

@media (max-width: 767px) {
  .table-responsive--sm thead {
    display: none; }
  .table-responsive--sm tbody tr:nth-child(odd) {
    background-color: f7f7f7; }
  .table-responsive--sm tbody tr td {
    padding-right: 15px; }
    .table-responsive--sm tbody tr td:last-child {
      padding-right: 15px; }
  .table-responsive--sm tr th, .table-responsive--sm tr td {
    display: block;
    padding-left: 45% !important;
    text-align: right !important;
    border-top: 1px solid rgba(0, 0, 0, 0.08) !important; }
    .table-responsive--sm tr th:first-child, .table-responsive--sm tr td:first-child {
      border-top: none !important; }
  .table-responsive--sm [data-label]::before {
    display: block; } }

@media (max-width: 1199px) {
  *[class*="table-responsive--"].data-label--none tr th, *[class*="table-responsive--"].data-label--none tr td {
    padding-left: .75rem; } }

/* table css end */
/* form css start */
.form-group {
  margin-bottom: 0.9375rem; }

.form--control {
  padding: 0.625rem 1.25rem;
  border: 1px solid #4380e4;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #000;
  height: 3.125rem; }
  .form--control:focus {
    background-color: #fff;
    border-color: #4380e4 !important;
    box-shadow: 0 0 5px rgba(67, 128, 228, 0.35);
    color: #000; }
  .form--control:placeholder-shown {
    border-color: lightgray;
    color: #565656; }
  .form--control[readonly] {
    background-color: #fff; }
  .form--control.style--two {
    border-width: 0 0 1px 0;
    padding: 0 0 0.625rem 0;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    font-family: "Exo 2", sans-serif;
    border-bottom-color: #999999; }
    .form--control.style--two:focus {
      box-shadow: none; }
  .form--control.form-control-md {
    height: 45px; }
  .form--control.form-control-sm {
    height: 35px; }

.custom-icon-field {
  position: relative; }
  .custom-icon-field i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0.75rem; }
  .custom-icon-field i[class*="la-"] {
    font-size: 1.25rem; }
  .custom-icon-field .form--control {
    padding-left: 2.5rem; }

.select {
  padding: 0.625rem 1.25rem;
  width: 100%;
  border: 1px solid lightgray;
  cursor: pointer;
  color: #565656;
  background-color: #fff;
  height: 3.125rem;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px; }
  .select option {
    padding: 0.625rem 0;
    display: block;
    border-top: 1px solid #e5e5e5; }
  .select.style--trans {
    background-color: transparent;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.5); }
    .select.style--trans option {
      color: #363636; }
  .select.select-sm {
    height: 2.1875rem;
    font-size: 0.875rem;
    padding: 0.3125rem; }

textarea {
  min-height: 9.375rem !important;
  resize: none;
  width: 100%; }

label {
  color: #636363;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500; }

.input-group > .form--control,
.input-group > .select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0; }

.input-group select {
  background-color: transparent;
  border: none; }

.custom--radio {
  position: relative;
  padding-left: 0; }
  .custom--radio input[type=radio] {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    cursor: pointer; }
    .custom--radio input[type=radio]:checked ~ label::before {
      border-width: 2px;
      border-color: #4380e4; }
    .custom--radio input[type=radio]:checked ~ label::after {
      opacity: 1; }
  .custom--radio label {
    margin-bottom: 0;
    position: relative;
    padding-left: 20px;
    font-size: 0.875rem;
    font-weight: 400; }
    .custom--radio label::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 0;
      width: 15px;
      height: 15px;
      border: 1px solid #888888;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }
    .custom--radio label::after {
      position: absolute;
      content: '';
      top: 8px;
      left: 4px;
      width: 7px;
      height: 7px;
      background-color: #4380e4;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      opacity: 0;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s; }

.custom--checkbox {
  padding-left: 1.5625rem; }
  .custom--checkbox input {
    display: none; }
    .custom--checkbox input:checked ~ label::before {
      content: "\f14a";
      color: #4380e4; }
  .custom--checkbox label {
    position: relative;
    font-size: 0.9375rem;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 0; }
    .custom--checkbox label::before {
      position: absolute;
      content: "\f04d";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      top: 3px;
      left: -1.5625rem;
      font-size: 1.25rem;
      line-height: 1; }

.wrong-info .form--control {
  border-color: #ea5455 !important;
  box-shadow: 0 0 6px 1px rgba(234, 84, 85, 0.3) !important; }

.select2-basic ~ .select2-container {
  display: block;
  width: 100% !important;
  max-width: 100% !important;
  height: 50px; }

.select2-container .select2-dropdown {
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); }

.select2-container .selection, .select2-container .select2-selection--single, .select2-container .select2-selection__rendered {
  display: block; }

.select2-container .select2-selection--single {
  height: 50px;
  border-color: lightgray; }
  .select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 50px;
    padding-left: 1.25rem; }
  .select2-container .select2-selection--single .select2-selection__arrow {
    height: 50px; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #4380e4; }

.select2-dropdown ::-webkit-scrollbar {
  width: 0.5rem; }

.select2-dropdown ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.select2-dropdown ::-webkit-scrollbar-thumb {
  background-color: #2A3042; }

/* form css end*/
/* card css start */
.custom--card {
  box-shadow: 0 0.75rem 1.5rem #12263f08;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  overflow: hidden; }
  .custom--card .card-header {
    background-color: #ffffff;
    padding: 0.9375rem 1.5625rem;
    border-color: #f0f1f5; }
  .custom--card .card-body {
    padding: 1.5625rem; }
    @media (max-width: 575px) {
      .custom--card .card-body {
        padding: 0.9375rem; } }

/* card css end */
/* modal css start */
.modal {
  z-index: 999999; }

.modal-open {
  overflow: hidden;
  overflow-y: auto;
  padding-right: 0 !important; }

/* modal css end */
/* header start */
.header__bottom {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15); }
  @media (max-width: 1199px) {
    .header__bottom {
      padding: 0.625rem 0; } }

.header .site-logo img {
  max-width: 10.9375rem;
  max-height: 3.75rem; }
  @media (max-width: 1199px) {
    .header .site-logo img {
      max-width: 9.375rem; } }

.header .site-logo.site-title {
  font-size: 1.5rem; }

.header .main-menu {
  margin-left: 4.375rem; }
  @media (max-width: 1199px) {
    .header .main-menu {
      margin-left: 0;
      padding: 0.9375rem 0; } }
  .header .main-menu li {
    position: relative; }
    @media (max-width: 1199px) {
      .header .main-menu li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }
    .header .main-menu li:last-child a {
      padding-right: 0; }
    .header .main-menu li.menu_has_children {
      position: relative; }
      .header .main-menu li.menu_has_children.open > .sub-menu {
        display: block; }
      .header .main-menu li.menu_has_children > a {
        padding-right: 1.5625rem; }
        @media (max-width: 1199px) {
          .header .main-menu li.menu_has_children > a {
            display: block; } }
        .header .main-menu li.menu_has_children > a::before {
          position: absolute;
          content: "\f067";
          font-family: "Line Awesome Free";
          font-weight: 900;
          top: 15px;
          right: 0;
          color: #fff; }
          @media (max-width: 1199px) {
            .header .main-menu li.menu_has_children > a::before {
              display: block;
              top: 0.5625rem;
              color: #fff; } }
      .header .main-menu li.menu_has_children:hover > a::before {
        content: "\f068";
        color: #4380e4; }
    .header .main-menu li a {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      padding: 0.9375rem 0.9375rem 0.9375rem 0;
      font-size: 0.9375rem;
      color: #fff; }
      @media (max-width: 1199px) {
        .header .main-menu li a {
          color: #fff;
          padding: 0.5rem 0;
          display: block; } }
      .header .main-menu li a:hover, .header .main-menu li a:focus {
        color: #4380e4; }
    .header .main-menu li .sub-menu {
      position: absolute;
      width: 220px;
      top: 105%;
      left: 0;
      z-index: 9999;
      background-color: #fff;
      padding: 0.625rem 0;
      -webkit-box-shadow: 0px 18px 54px -8px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 5px 25px 2px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      opacity: 0;
      visibility: hidden; }
      @media (max-width: 1199px) {
        .header .main-menu li .sub-menu {
          opacity: 1;
          visibility: visible;
          display: none;
          position: static;
          -webkit-transition: none;
          -o-transition: none;
          transition: none;
          width: 100%;
          background-color: #2A3042;
          border: none; } }
      .header .main-menu li .sub-menu li {
        border-bottom: 1px dashed #e5e5e5; }
        @media (max-width: 1199px) {
          .header .main-menu li .sub-menu li {
            border-color: rgba(255, 255, 255, 0.15); } }
        .header .main-menu li .sub-menu li:last-child {
          border-bottom: none; }
        .header .main-menu li .sub-menu li a {
          padding: 0.5rem 1.5625rem;
          display: block;
          color: #565656;
          position: relative;
          font-size: 0.9375rem;
          text-transform: capitalize; }
          @media (max-width: 1199px) {
            .header .main-menu li .sub-menu li a {
              color: #fff; } }
          .header .main-menu li .sub-menu li a:hover {
            background-color: rgba(67, 128, 228, 0.05);
            color: #4380e4; }
      .header .main-menu li .sub-menu li + li {
        margin-left: 0; }
    .header .main-menu li:hover > .sub-menu {
      top: 100%;
      opacity: 1;
      visibility: visible; }
  .header .main-menu li + li {
    margin-left: 1.25rem; }
    @media (max-width: 1199px) {
      .header .main-menu li + li {
        margin-left: 0; } }
  .header .main-menu .sub-menu li.menu_has_children > a::before {
    top: 9px;
    right: 8px; }
  .header .main-menu .sub-menu li.menu_has_children > .sub-menu {
    left: 100%;
    top: 0; }
    @media (max-width: 1199px) {
      .header .main-menu .sub-menu li.menu_has_children > .sub-menu {
        padding-left: 15px; } }

.header .nav-right {
  padding-left: 3.125rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center; }
  @media (max-width: 1199px) {
    .header .nav-right {
      padding-left: 0; } }

.header-search-form {
  position: relative; }
  @media (max-width: 1199px) {
    .header-search-form {
      display: none; } }
  .header-search-form__input {
    width: 350px;
    height: 45px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    border-radius: 999px;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
    padding: 0.625rem 2.1875rem 0.625rem 1.25rem;
    font-size: 0.875rem; }
    .header-search-form__input::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .header-search-form__input::-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .header-search-form__input:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    .header-search-form__input:-moz-placeholder {
      color: rgba(255, 255, 255, 0.7); }
    @media (max-width: 1399px) {
      .header-search-form__input {
        width: 250px; } }
  .header-search-form__btn {
    position: absolute;
    top: 7px;
    right: 10px;
    background-color: transparent;
    color: #fff; }
  .header-search-form.header-search-form-mobile {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden; }
    @media (max-width: 1199px) {
      .header-search-form.header-search-form-mobile {
        display: block; } }
    .header-search-form.header-search-form-mobile.active {
      top: 60px;
      opacity: 1;
      visibility: visible; }
    .header-search-form.header-search-form-mobile .header-search-form__input {
      width: 100%;
      border-radius: 0;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      -ms-border-radius: 0;
      -o-border-radius: 0; }

.header-search-open-btn {
  margin-left: 1.25rem;
  background-color: transparent;
  padding: 0;
  color: #fff;
  font-size: 1.5rem;
  display: none; }
  @media (max-width: 1199px) {
    .header-search-open-btn {
      display: inline-block; } }
  .header-search-open-btn.active i::before {
    content: "\f00d"; }

@media (max-width: 1199px) {
  .navbar-collapse {
    margin-top: 20px !important;
    background-color: #071e3e;
    padding: 0 1.875rem 1.25rem 1.875rem; } }

@media (max-width: 767px) {
  .navbar-collapse {
    max-height: 20rem;
    overflow: auto; } }

.navbar-toggler {
  padding: 0;
  border: none; }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none; }

.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: 2.1875rem;
  height: 1.25rem;
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #fff;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  cursor: pointer; }

.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out; }
  @media (max-width: 1199px) {
    .menu-toggle:before, .menu-toggle:after {
      background-color: #fff; } }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-ms-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

/* header end */
/* hero section css start */
.hero {
  padding: 5rem 0; }
  .hero__title {
    font-size: 2.75rem; }

/* hero section css end */
/* board section css start */
.main-section {
  padding: 3.125rem 9.375rem; }

.forum-block {
  background-color: #ffb88021;
  overflow: hidden;
  margin-top: 2.5rem;
  border: 1px solid #e0e0e0; }
  .forum-block:first-child {
    margin-top: 0; }
  .forum-block__header {
    border-bottom: 1px solid #e0e0e0;
    padding: 0.9375rem 1.5625rem; }
  .forum-block__title {
    color: #fff; }
  .forum-block__body {
    padding: 1.25rem 1.5625rem; }
    @media (max-width: 575px) {
      .forum-block__body {
        padding: 1.25rem 0; } }

.single-thread {
  background-color: #fff;
  padding: 1.5625rem;
  align-items: center; }
  .single-thread + .single-thread {
    margin-top: 0.9375rem; }
  .single-thread__left {
    width: 60%;
    padding-right: 1.875rem; }
    @media (max-width: 1649px) {
      .single-thread__left {
        width: 100%;
        padding-right: 0; } }
  .single-thread__title {
    font-size: 1.125rem; }
  .single-thread__right {
    width: 40%;
    padding-left: 1.875rem;
    border-left: 1px solid #e0e0e0; }
    @media (max-width: 1649px) {
      .single-thread__right {
        width: 100%;
        padding-left: 0;
        border-left: 0;
        padding-top: 1.25rem; } }
    .single-thread__right .top {
      padding-bottom: 0.625rem; }
      .single-thread__right .top__list li {
        width: calc(100% / 3); }
      .single-thread__right .top__list-user {
        display: flex;
        align-items: center; }
        .single-thread__right .top__list-user li {
          width: 30px;
          height: 30px;
          overflow: hidden;
          border: 2px solid #fff;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
          position: relative;
          z-index: 3; }
          .single-thread__right .top__list-user li:not(:first-child) {
            margin-left: -10px;
            z-index: 1; }
          .single-thread__right .top__list-user li:nth-child(2) {
            z-index: 2; }
          .single-thread__right .top__list-user li img {
            width: inherit;
            height: inherit; }
    .single-thread__right .bottom {
      padding-top: 0.625rem;
      border-top: 1px solid #e0e0e0; }
    .single-thread__right .latest-topic {
      align-items: center; }
      .single-thread__right .latest-topic__thumb {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        overflow: hidden; }
        .single-thread__right .latest-topic__thumb img {
          width: inherit;
          height: inherit; }
      .single-thread__right .latest-topic__content {
        width: calc(100% - 30px);
        padding-left: 0.9375rem; }
      .single-thread__right .latest-topic__title {
        font-size: 0.9375rem; }
        .single-thread__right .latest-topic__title a {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; }

.sub-forum-list {
  margin: -0.1875rem -0.3125rem; }
  .sub-forum-list li {
    padding: 0.1875rem 0.3125rem; }
    .sub-forum-list li::after {
      content: ','; }

/* board section css end*/
/* search result section css start */
.post-filter-area {
  background-color: #ffb88021;
  border: 1px solid #e0e0e0;
  padding: 0.625rem 1.25rem; }

.post-filter-list li {
  padding: 0.1875rem 0.625rem;
  font-size: 0.875rem; }
  .post-filter-list li.active a::after {
    opacity: 1; }
  .post-filter-list li a {
    color: #565656;
    font-weight: 500;
    position: relative; }
    .post-filter-list li a::after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0;
      width: 100%;
      height: 2px;
      opacity: 0; }

.single-post {
  padding: 1.5625rem;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  position: relative; }
  .single-post .forum-badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 0.75rem;
    color: #fff;
    padding: 0.0625rem 0.625rem 0.0625rem 1.25rem;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 11% 50%, 0% 0%);
    -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 11% 50%, 0% 0%); }
  .single-post + .single-post {
    margin-top: 0.9375rem; }
  .single-post__thumb {
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%; }
    .single-post__thumb img {
      width: inherit;
      height: inherit;
      object-fit: cover;
      -o-object-fit: cover;
      object-position: center;
      -o-object-position: center; }
  .single-post__content {
    width: calc(100% - 65px);
    padding-left: 1.5625rem; }
    @media (max-width: 420px) {
      .single-post__content {
        width: 100%;
        padding-left: 0;
        margin-top: 0.625rem; } }
  .single-post__title {
    font-size: 1.25rem; }
    .single-post__title a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
  .single-post__meta {
    margin: -0.1875rem -0.625rem; }
    .single-post__meta li {
      font-size: 0.875rem;
      padding: 0.1875rem 0.625rem; }
  .single-post__footer {
    width: 100%; }
  .single-post__action-list {
    justify-content: space-between;
    padding-top: 0.9375rem;
    border-top: 1px solid #e0e0e0; }
    .single-post__action-list .left,
    .single-post__action-list .right {
      margin: -0.1875rem -0.9375rem; }
      .single-post__action-list .left li,
      .single-post__action-list .right li {
        padding: 0.1875rem 0.9375rem; }
        .single-post__action-list .left li a,
        .single-post__action-list .right li a {
          display: flex;
          align-items: center;
          color: #373e4a;
          font-weight: 500;
          font-size: 0.875rem; }
        .single-post__action-list .left li i,
        .single-post__action-list .right li i {
          line-height: 1;
          margin-right: 0.1875rem;
          font-size: 1.5rem;
          color: #565656; }

/* search result section css end */
/* post details section css start */
.post-details__badge {
  font-size: 0.875rem;
  color: #fff;
  padding: 0.0625rem 1.5625rem 0.0625rem 0.625rem;
  -webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
  clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0); }

.post-details__title {
  font-size: 1.75rem; }

.post-details__tags {
  margin: -0.1875rem -0.3125rem; }
  .post-details__tags li {
    padding: 0.1875rem 0.3125rem; }
    .post-details__tags li a {
      padding: 0.0625rem 0.625rem;
      background-color: #ffb88021;
      border: 1px solid #e0e0e0;
      color: #565656;
      font-size: 0.75rem;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px; }

.post-details__social {
  margin: -0.1875rem -0.3125rem; }
  .post-details__social li {
    padding: 0.1875rem 0.3125rem; }

.post-author__thumb {
  margin-top: 6px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden; }
  .post-author__thumb img {
    width: inherit;
    height: inherit; }

.post-author__content {
  width: calc(100% - 60px);
  padding-left: 1.25rem; }
  @media (max-width: 420px) {
    .post-author__content {
      width: 100%;
      padding-left: 0;
      margin-top: 0.9375rem; } }

.post-author__meta {
  margin: -0.3125rem -0.625rem; }
  .post-author__meta li {
    padding: 0.3125rem 0.625rem; }

.comment-wrapper__thumb {
  margin-top: 6px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden; }
  @media (max-width: 420px) {
    .comment-wrapper__thumb {
      width: 40px;
      height: 40px; } }
  .comment-wrapper__thumb img {
    width: inherit;
    height: inherit; }

.comment-wrapper__content {
  width: calc(100% - 60px);
  padding-left: 1.25rem; }
  @media (max-width: 420px) {
    .comment-wrapper__content {
      width: calc(100% - 40px); } }

.single-comment {
  padding: 1.25rem 0;
  border-bottom: 1px solid #e0e0e0; }
  .single-comment:first-child {
    padding-top: 0; }
  .single-comment:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .single-comment__thumb {
    margin-top: 6px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden; }
    .single-comment__thumb img {
      width: inherit;
      height: inherit; }
  .single-comment__content {
    width: calc(100% - 40px);
    padding-left: 1.25rem; }

/* post details section css end */
/* dashboard section css start */
.d-widget {
  padding: 0.9375rem;
  background-color: #fff;
  border: 1px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.05);
  position: relative; }
  .d-widget:hover {
    box-shadow: 0 8px 45px rgba(0, 0, 0, 0.1); }
    .d-widget:hover .d-widget__icon {
      color: #fff; }
  .d-widget__btn {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    font-size: 1.125rem; }
    .d-widget__btn:hover {
      color: #fff; }
  .d-widget__icon {
    width: 3.75rem;
    height: 3.75rem;
    background-color: rgba(67, 128, 228, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem; }
  .d-widget__content {
    width: calc(100% - 3.75rem);
    padding-left: 1.25rem; }
    .d-widget__content .amount {
      font-size: 2rem; }

/* dashboard section css end */
/* user-sidebar css start */
.user-widget {
  padding: 1.875rem; }
  .user-widget .thumb {
    max-height: 230px;
    overflow: hidden; }

.user-info-list li {
  padding: 6px 0;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center; }
  .user-info-list li:first-child {
    padding-top: 0; }
  .user-info-list li:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .user-info-list li i {
    width: 30px;
    font-size: 1.375rem; }
  .user-info-list li p {
    width: calc(100% - 30px);
    padding-left: 15px; }

.user-menu-widget {
  margin-top: 1.875rem;
  padding: 0.9375rem;
  background-color: #fff;
  box-shadow: 0 5px 35px rgba(0, 0, 0, 0.05); }

.user-menu li {
  border-bottom: 1px dashed #fafafa; }
  .user-menu li.active {
    border-bottom: none; }
    .user-menu li.active a {
      color: #fff; }
    .user-menu li.active:hover a {
      color: #fff; }
  .user-menu li:last-child {
    border-bottom: none; }
  .user-menu li:hover a {
    background-color: rgba(67, 128, 228, 0.15); }
  .user-menu li a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    color: #565656;
    padding: 10px 15px; }
    .user-menu li a i {
      font-size: 1.125rem;
      margin-right: 8px; }

/* user-sidebar css end */
/* sidebar css start */
.sidebar-widget {
  background-color: #ffb88021;
  border: 1px solid #e0e0e0;
  overflow: hidden; }
  .sidebar-widget__header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #e0e0e0; }
  .sidebar-widget__title {
    font-size: 1.125rem;
    color: #fff; }
  .sidebar-widget__body {
    padding: 0.9375rem 1.25rem; }

.category-list li.active {
  background-color: rgba(67, 128, 228, 0.1); }

.category-list li a {
  font-size: 0.9375rem;
  color: #565656;
  padding: 0.5rem 0.625rem;
  display: flex;
  align-items: center; }
  .category-list li a i {
    font-size: 1.25rem;
    margin-right: 0.375rem; }

.discussion-list .single-discussion {
  padding: 0.625rem 0;
  border-bottom: 1px dashed #e0e0e0; }
  .discussion-list .single-discussion:first-child {
    padding-top: 0; }
  .discussion-list .single-discussion:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .discussion-list .single-discussion__title {
    font-size: 0.9375rem; }

.statistics-list .single-stat {
  width: 50%;
  text-align: center;
  padding: 0.9375rem;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0; }
  .statistics-list .single-stat:nth-last-child(-n + 2) {
    border-bottom: none; }
  .statistics-list .single-stat:nth-of-type(2n + 2) {
    border-right: none; }

.contributor-list .single-contributor {
  padding: 0.625rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #e0e0e0; }
  .contributor-list .single-contributor:first-child {
    padding-top: 0; }
  .contributor-list .single-contributor:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .contributor-list .single-contributor__thumb {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden;
    margin-right: 0.625rem; }
    .contributor-list .single-contributor__thumb img {
      width: inherit;
      height: inherit; }
  .contributor-list .single-contributor__name {
    font-size: 0.9375rem; }
  .contributor-list .single-contributor__comment {
    margin-left: auto; }

.unanswered-list .single-unanswered {
  padding: 0.625rem 0;
  border-bottom: 1px dashed #e0e0e0; }
  .unanswered-list .single-unanswered:first-child {
    padding-top: 0; }
  .unanswered-list .single-unanswered:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .unanswered-list .single-unanswered__top {
    display: flex; }
  .unanswered-list .single-unanswered__thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden; }
    .unanswered-list .single-unanswered__thumb img {
      width: inherit;
      height: inherit; }
  .unanswered-list .single-unanswered__content {
    width: calc(100% - 25px);
    padding-left: 0.625rem; }
  .unanswered-list .single-unanswered__name {
    font-size: 0.875rem; }
  .unanswered-list .single-unanswered__title {
    font-size: 0.9375rem;
    margin-top: 0.625rem; }

.topic-list .single-topic {
  padding: 0.625rem 0;
  border-bottom: 1px dashed #e0e0e0; }
  .topic-list .single-topic:first-child {
    padding-top: 0; }
  .topic-list .single-topic:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  .topic-list .single-topic__thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    overflow: hidden; }
    .topic-list .single-topic__thumb img {
      width: inherit;
      height: inherit; }
  .topic-list .single-topic__content {
    width: calc(100% - 25px);
    padding-left: 0.9375rem; }
  .topic-list .single-topic__title {
    font-size: 0.9375rem; }

/* sidebar css end */
/* footer section css start */
.footer-section {
  padding: 1.5625rem 0; }
  .footer-section p, .footer-section li, .footer-section span, .footer-section i, .footer-section a {
    color: rgba(255, 255, 255, 0.9); }

.footer-logo img {
  max-width: 150px;
  max-height: 50px; }

.social-link li {
  padding: 0.1875rem 0.4375rem; }

/* footer section css end */
/* account section css start */
.account-wrapper {
  padding: 1.5625rem;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15); }

.account-thumb {
  padding: 3.125rem; }
  @media (max-width: 575px) {
    .account-thumb {
      padding: 1.875rem; } }

.account-content {
  padding: 1.5625rem; }
  @media (max-width: 991px) {
    .account-content {
      padding: 0.625rem 0; } }

/* account section css end */
/* contact section css start */
.conatact-section {
  padding-top: 15.625rem;
  padding-bottom: 7.5rem;
  position: relative; }
  .conatact-section::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 15.625rem; }

.contact-wrapper {
  padding: 3.125rem;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.15);
  border: 1px solid #e0e0e0;
  margin-top: -150px;
  position: relative;
  z-index: 1; }
  @media (max-width: 991px) {
    .contact-wrapper {
      padding: 1.875rem; } }

.contact-item {
  align-items: center;
  padding: 1.25rem;
  background-color: #ffb88021;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  text-align: center;
  height: 100%; }
  .contact-item i {
    font-size: 3.25rem;
    line-height: 1;
    margin-bottom: 1.25rem; }
  .contact-item a {
    color: #565656;
    word-break: break-word; }

/* contact section css end */
