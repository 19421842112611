/* reset css start */
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;500;600;700&family=Roboto:wght@400;500;600&display=swap');

$heading-font: 'Exo 2', sans-serif;
$para-font: 'Roboto', sans-serif;

html {
  scroll-behavior: smooth; }
body {
  font-family: $para-font;
  color: $para-color;
  font-size: rem(16px);
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  line-height: 1.7;
  // background-color: #F4F5F7
  background-color: #fff;
  @include transition(all 0.5s);
  overflow-x: hidden;
  @extend %transition;
  &::-webkit-scrollbar {
    width: 8px; }
  &::-webkit-scrollbar-track {
    background: #f2f2f2; }
  &::-webkit-scrollbar-thumb {
    @extend %base-color;
    border-radius: 20px;
    border: 2px solid #f2f2f2; } }

@include keyframes(spinRoll) {
  from {
    @include transform(rotate(0deg)); }
  to {
    @include transform(rotate(360deg)); } }
img {
  max-width: 100%;
  height: auto;
  user-select: none; }
select {
  cursor: pointer; }
ul, ol {
  padding: 0;
  margin: 0;
  list-style: none; }
button {
  cursor: pointer; }
*:focus {
  outline: none; }
button {
  border: none; }
button:focus {
  outline: none; }
span {
  display: inline-block; }
a:hover {
  color: $base-color; }
hr {
  background-color: $border-color;
  opacity: 0.65; }
/* reset css end */
