/* card css start */
.custom--card {
  box-shadow: 0 0.75rem 1.5rem #12263f08;
  background-color: #ffffff;
  border: 1px solid $border-color;
  @extend %bs-8;
  overflow: hidden;
  .card-header {
    background-color: #ffffff;
    padding: rem(15px) rem(25px);
    border-color: #f0f1f5; }
  .card-body {
    padding: rem(25px);
    @include media(575px) {
      padding: rem(15px); } } }
/* card css end */
