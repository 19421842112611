/* dashboard section css start */
.d-widget {
  padding: rem(15px);
  background-color: #fff;
  @extend %bs-8;
  border: 1px;
  @include d-flex;
  align-items: center;
  box-shadow: 0 5px 35px rgba(#000, 0.05);
  @extend %transition;
  position: relative;
  &:hover {
    box-shadow: 0 8px 45px rgba(#000, 0.1);
    .d-widget__icon {
      @extend %base-color;
      color: #fff; } }
  &__btn {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -5px;
    right: -5px;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend  %base-color;
    @include border-radius(50%);
    font-size: rem(18px);
    @extend %text--white;
    &:hover {
      color: #fff; } }
  &__icon {
    width: rem(60px);
    height: rem(60px);
    background-color: rgba($base-color, 0.15);
    @extend %text--base;
    @extend %bs-5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(32px);
    @extend %transition; }
  &__content {
    width: calc(100% - #{rem(60px)});
    padding-left: rem(20px);
    .amount {
      font-size: rem(32px); } } }
/* dashboard section css end */


/* user-sidebar css start */
.user-widget {
  padding: rem(30px);
  @extend %base-color-two;
  @extend %bs-8;
  .thumb {
    max-height: 230px;
    overflow: hidden;
    @extend %bs-5; } }
.user-info-list {
  li {
    padding: 6px 0;
    border-bottom: 1px dashed rgba(#fff, 0.15);
    @include d-flex;
    align-items: center;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    i {
      width: 30px;
      font-size: rem(22px);
      @extend %text--base; }
    p {
      width: calc(100% - 30px);
      padding-left: 15px; } } }
.user-menu-widget {
  margin-top: rem(30px);
  padding: rem(15px);
  background-color: #fff;
  box-shadow: 0 5px 35px rgba(#000, 0.05);
  @extend %bs-8; }
.user-menu {
  li {
    border-bottom: 1px dashed lighten($border-color , 10%);
    &.active {
      border-bottom: none;
      a {
        @extend %base-color;
        color: #fff;
        @extend %bs-5; }
      &:hover {
        a {
          @extend %base-color;
          color: #fff; } } }
    &:last-child {
      border-bottom: none; }
    &:hover {
      a {
        background-color: rgba($base-color, 0.15);
        @extend %text--base;
        @extend %bs-5; } }
    a {
      @include d-flex;
      align-items: center;
      color: $para-color;
      @extend %transition;
      padding: 10px 15px;
      i {
        font-size: rem(18px);
        margin-right: 8px; } } } }
/* user-sidebar css end */
