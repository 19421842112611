
/* contact section css start */
.conatact-section {
  $top-height: rem(250px);
  padding-top: $top-height;
  padding-bottom: rem(120px);
  position: relative;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: $top-height;
    @extend %base-color-two; } }

.contact-wrapper {
  padding: rem(50px);
  @extend %bg--white;
  box-shadow: 0 5px 25px rgba(#000, 0.15);
  @extend %bs-8;
  border: 1px solid $border-color;
  margin-top: -150px;
  position: relative;
  z-index: 1;
  @include media(991px) {
    padding: rem(30px); } }
.contact-item {
  align-items: center;
  padding: rem(20px);
  background-color: #ffb88021;
  box-shadow: 0 5px 10px rgba(#000, 0.1);
  @extend %bs-8;
  border: 1px solid $border-color;
  text-align: center;
  height: 100%;
  i {
    font-size: rem(52px);
    line-height: 1;
    margin-bottom: rem(20px);
    @extend %text--base; }
  a {
    color: $para-color;
    word-break: break-word;
    &:hover {
      @extend %text--base; } } }

/* contact section css end */
