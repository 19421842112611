/* footer section css start */
.footer-section {
  @extend %base-color-two;
  padding: rem(25px) 0;
  p, li, span, i, a {
    color: rgba(#fff, 0.9); } }
.footer-logo {
  img {
    max-width: 150px;
    max-height: 50px; } }

.social-link {
  li {
    padding: rem(3px) rem(7px);
    a {
      &:hover {
        i {
          @extend %text--base; } } } } }
/* footer section css end */
