/* board section css start */
.main-section {
  padding: rem(50px) rem(150px); }
.forum-block {
  background-color: #ffb88021;
  overflow: hidden;
  @extend %bs-8;
  margin-top: rem(40px);
  // box-shadow: 0 5px 25px rgba(#000, 0.15)
  border: 1px solid $border-color;
  &:first-child {
    margin-top: 0; }
  &__header {
    border-bottom: 1px solid $border-color;
    padding: rem(15px) rem(25px);
    // background-color: #FBF2D4
    @extend %base-color-two; }
  &__title {
    color: #fff; }
  &__body {
    padding: rem(20px) rem(25px);
    @include media(575px) {
      padding: rem(20px) 0; } } }

.single-thread {
  @extend %d-flex;
  background-color: #fff;
  padding: rem(25px);
  align-items: center;
  @extend %bs-5;
  & + & {
    margin-top: rem(15px); }
  &__left {
    width: 60%;
    padding-right: rem(30px);
    @include media(1649px) {
      width: 100%;
      padding-right: 0; } }
  &__title {
    font-size: rem(18px); }
  &__right {
    width: 40%;
    padding-left: rem(30px);
    border-left: 1px solid $border-color;
    @include media(1649px) {
      width: 100%;
      padding-left: 0;
      border-left: 0;
      padding-top: rem(20px); }
    .top {
      padding-bottom: rem(10px);
      &__list {
        @extend %d-flex;
        li {
          width: calc(100% / 3); } }
      &__list-user {
        display: flex;
        align-items: center;
        li {
          width: 30px;
          height: 30px;
          overflow: hidden;
          border: 2px solid #fff;
          @include border-radius(50%);
          box-shadow: 0 0 5px rgba(#000, 0.35);
          position: relative;
          z-index: 3;
          &:not(:first-child) {
            margin-left: -10px;
            z-index: 1; }
          &:nth-child(2) {
            z-index: 2; }
          img {
            width: inherit;
            height: inherit;
            @extend %obj-fit; } } } }
    .bottom {
      padding-top: rem(10px);
      border-top: 1px solid $border-color; }
    .latest-topic {
      @extend %d-flex;
      align-items: center;
      &__thumb {
        width: 30px;
        height: 30px;
        @include border-radius(50%);
        overflow: hidden;
        img {
          width: inherit;
          height: inherit;
          @extend %obj-fit; } }
      &__content {
        width: calc(100% - 30px);
        padding-left: rem(15px); }
      &__title {
        font-size: rem(15px);
        a {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; } } } } }
.sub-forum-list {
  margin: rem(-3px) rem(-5px);
  li {
    padding: rem(3px) rem(5px);
    &::after {
      content: ','; }
    a {
      @extend %text--base; } } }
/* board section css end*/


/* search result section css start */
.post-filter-area {
  background-color: #ffb88021;
  border: 1px solid $border-color;
  @extend %bs-5;
  padding: rem(10px) rem(20px); }
.post-filter-list {
  @extend %d-flex;
  li {
    padding: rem(3px) rem(10px);
    font-size: rem(14px);
    &.active {
      a {
        &::after {
          opacity: 1; } } }
    a {
      color: $para-color;
      font-weight: 500;
      position: relative;
      &::after {
        position: absolute;
        content: '';
        bottom: -16px;
        left: 0;
        width: 100%;
        height: 2px;
        @extend %base-color;
        opacity: 0; } } } }

.single-post {
  padding: rem(25px);
  // background-color: #F6F5F1
  background-color: #fff;
  @extend %bs-8;
  @extend %d-flex;
  border: 1px solid $border-color;
  position: relative;
  .forum-badge {
    position: absolute;
    top: 0;
    right: 0;
    font-size: rem(12px);
    @extend %base-color;
    color: #fff;
    padding: rem(1px) rem(10px) rem(1px) rem(20px);
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 11% 50%, 0% 0%);
    -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 11% 50%, 0% 0%); }
  & + & {
    margin-top: rem(15px); }
  &__thumb {
    width: 65px;
    height: 65px;
    overflow: hidden;
    @include border-radius(50%);
    img {
      width: inherit;
      height: inherit;
      @include object-fit; } }
  &__content {
    width: calc(100% - 65px);
    padding-left: rem(25px);
    @include media(420px) {
      width: 100%;
      padding-left: 0;
      margin-top: rem(10px); } }
  &__title {
    font-size: rem(20px);
    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; } }
  &__meta {
    margin: rem(-3px) rem(-10px);
    li {
      font-size: rem(14px);
      padding: rem(3px) rem(10px); } }
  &__footer {
    width: 100%; }
  &__action-list {
    justify-content: space-between;
    padding-top: rem(15px);
    border-top: 1px solid $border-color;
    .left ,
    .right {
      margin: rem(-3px) rem(-15px);
      @extend %d-flex;
      li {
        padding: rem(3px) rem(15px);
        a {
          display: flex;
          align-items: center;
          color: $heading-color;
          font-weight: 500;
          font-size: rem(14px); }
        i {
          line-height: 1;
          margin-right: rem(3px);
          font-size: rem(24px);
          color: $para-color; } } } } }
/* search result section css end */


/* post details section css start */
.post-details {
  &__badge {
    @extend %base-color;
    font-size: rem(14px);
    color: #fff;
    padding: rem(1px) rem(25px) rem(1px) rem(10px);
    -webkit-clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 0); }
  &__title {
    font-size: rem(28px); }
  &__tags {
    @extend %d-flex;
    margin: rem(-3px) rem(-5px);
    li {
      padding: rem(3px) rem(5px);
      a {
        padding: rem(1px) rem(10px);
        background-color: #ffb88021;
        border: 1px solid $border-color;
        color: $para-color;
        font-size: rem(12px);
        @include border-radius(5px); } } }
  &__social {
    margin: rem(-3px) rem(-5px);
    li {
      padding: rem(3px) rem(5px); } } }

.post-author {
  @extend %d-flex;
  &__thumb {
    margin-top: 6px;
    width: 60px;
    height: 60px;
    @include border-radius(50%);
    overflow: hidden;
    img {
      width: inherit;
      height: inherit;
      @extend %obj-fit; } }
  &__content {
    width: calc(100% - 60px);
    padding-left: rem(20px);
    @include media(420px) {
      width: 100%;
      padding-left: 0;
      margin-top: rem(15px); } }
  &__meta {
    margin: rem(-5px) rem(-10px);
    li {
      padding: rem(5px) rem(10px); } } }

.comment-wrapper {
  @extend %d-flex;
  &__thumb {
    margin-top: 6px;
    width: 60px;
    height: 60px;
    @include border-radius(50%);
    overflow: hidden;
    @include media(420px) {
      width: 40px;
      height: 40px; }
    img {
      width: inherit;
      height: inherit;
      @extend %obj-fit; } }
  &__content {
    width: calc(100% - 60px);
    padding-left: rem(20px);
    @include media(420px) {
      width: calc(100% - 40px); } } }

.single-comment {
  padding: rem(20px) 0;
  border-bottom: 1px solid $border-color;
  @extend %d-flex;
  &:first-child {
    padding-top: 0; }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none; }
  &__thumb {
    margin-top: 6px;
    width: 40px;
    height: 40px;
    @include border-radius(50%);
    overflow: hidden;
    img {
      width: inherit;
      height: inherit;
      @extend %obj-fit; } }
  &__content {
    width: calc(100% - 40px);
    padding-left: rem(20px); } }
/* post details section css end */
