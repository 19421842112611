/* header start */
.header {
  &__bottom {
    @extend %base-color-two;
    padding: rem(8px) 0;
    border-bottom: 1px solid rgba(#fff, 0.15);
    @include media(1199px) {
      padding: rem(10px) 0; } }
  .site-logo {
    img {
      max-width: rem(175px);
      max-height: rem(60px);
      @include media(1199px) {
        max-width: rem(150px); } }
    &.site-title {
      font-size: rem(24px);
      @extend %text--base; } }
  .main-menu {
    margin-left: rem(70px);
    @include media(1199px) {
      margin-left: 0;
      padding: rem(15px) 0; }
    li {
      position: relative;
      @include media(1199px) {
        border-bottom: 1px solid rgba(#ffffff, 0.15); }
      &:last-child {
        a {
          padding-right: 0; } }
      &.menu_has_children {
        position: relative;
        &.open {
          >.sub-menu {
            display: block; } }
        >a {
          padding-right: rem(25px);
          @include media(1199px) {
            display: block; }
          &::before {
            position: absolute;
            content: "\f067";
            font-family: "Line Awesome Free";
            font-weight: 900;
            top: 15px;
            right: 0;
            color: #fff;
            @extend  %transition;
            @include media(1199px) {
              display: block;
              top: rem(9px);
              color: #fff; } } }
        &:hover {
          >a::before {
            content: "\f068";
            color: $base-color; } } }
      a {
        font-family: $para-font;
        font-weight: 500;
        padding: rem(15px) rem(15px) rem(15px) 0;
        font-size: rem(15px);
        color: #fff;
        @include media(1199px) {
          color: #fff;
          padding: rem(8px) 0;
          display: block; }
        &:hover,&:focus {
          color: $base-color; } }
      .sub-menu {
        position: absolute;
        width: 220px;
        top: 105%;
        left: 0;
        z-index: 9999;
        background-color: #fff;
        padding: rem(10px) 0;
        -webkit-box-shadow: 0px 18px 54px -8px rgba(0,0,0,0.15);
        box-shadow: 0px 5px 25px 2px rgba(0,0,0,0.1);
        @include border-radius(5px);
        @extend  %transition;
        opacity: 0;
        visibility: hidden;
        @include media(1199px) {
          opacity: 1;
          visibility: visible;
          display: none;
          position: static;
          @include transition(none);
          width: 100%;
          background-color: $base-color-two;
          border: none; }
        li {
          border-bottom: 1px dashed #e5e5e5;
          @include media(1199px) {
            border-color: rgba(#fff, 0.15); }
          &:last-child {
            border-bottom: none; }
          a {
            padding: rem(8px) rem(25px);
            display: block;
            color: $para-color;
            position: relative;
            font-size: rem(15px);
            text-transform: capitalize;
            @extend  %transition;
            @include media(1199px) {
              color: #fff; }
            &:hover {
              background-color: rgba($base-color, 0.05);
              color: $base-color; } } }
        li+li {
          margin-left: 0; } }
      &:hover > .sub-menu {
        top: 100%;
        opacity: 1;
        visibility: visible; } }
    li+li {
      margin-left: rem(20px);
      @include media(1199px) {
        margin-left: 0; } }
    .sub-menu {
      li.menu_has_children {
        >a {
          &::before {
            top: 9px;
            right: 8px; } }
        > .sub-menu {
          left: 100%;
          top: 0;
          @include media(1199px) {
            padding-left: 15px; } } } } }
  .nav-right {
    padding-left: rem(50px);
    @include d-flex;
    align-items: center;
    @include media(1199px) {
      padding-left: 0; } } }

.header-search-form {
  position: relative;
  @include media(1199px) {
    display: none; }
  &__input {
    width: 350px;
    height: 45px;
    @extend %base-color-two;
    border: 1px solid rgba(#fff, 0.15);
    @include border-radius(999px);
    @include placeholder(rgba(#fff, 0.7));
    padding: rem(10px) rem(35px) rem(10px) rem(20px);
    font-size: rem(14px);
    @include media(1399px) {
      width: 250px; } }
  &__btn {
    position: absolute;
    top: 7px;
    right: 10px;
    background-color: transparent;
    color: #fff; }
  &.header-search-form-mobile {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 50px;
    left: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    @extend %base-color-two;
    @extend %transition;
    @include media(1199px) {
      display: block; }
    &.active {
      top: 60px;
      opacity: 1;
      visibility: visible; }
    .header-search-form__input {
      width: 100%;
      @include border-radius(0); } } }

.header-search-open-btn {
  margin-left: rem(20px);
  background-color: transparent;
  padding: 0;
  color: #fff;
  font-size: rem(24px);
  display: none;
  @include media(1199px) {
    display: inline-block; }
  i {
    &::before {
      @extend %transition; } }
  &.active {
    i {
      &::before {
        content: "\f00d"; } } } }

.navbar-collapse {
  @include media(1199px) {
    margin-top: 20px !important;
    background-color: $bg-one;
    padding: 0 rem(30px) rem(20px) rem(30px); }
  @include media(767px) {
    max-height: rem(320px);
    overflow: auto; } }
.navbar-toggler {
  padding: 0;
  border: none;
  &:focus {
    outline: none;
    box-shadow: none; } }
.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: rem(35px);
  height: rem(20px);
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #fff;
  font-size: 0;
  @include transition(all 0.25s ease-in-out);
  cursor: pointer; }
.menu-toggle:before, .menu-toggle:after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  @include transform(translate(-50%, -50%));
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out;
  @include media(1199px) {
    background-color: #fff; } }
// create animation for when menu is fixed
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown; }
@include keyframes (fadeInDown) {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
/* header end */
