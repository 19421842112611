/* sidebar css start */
.sidebar-widget {
  background-color: #ffb88021;
  border: 1px solid $border-color;
  @extend %bs-8;
  overflow: hidden;
  &__header {
    padding: rem(12px) rem(20px);
    border-bottom: 1px solid $border-color;
    // background-color: #FBF2D4
    @extend %base-color-two; }
  &__title {
    font-size: rem(18px);
    color: #fff; }
  &__body {
    padding: rem(15px) rem(20px); } }

.category-list {
  li {
    &:hover {
      a {
        @extend %text--base; } }
    &.active {
      background-color: rgba($base-color, 0.1);
      a {
        @extend %text--base; } }
    a {
      font-size: rem(15px);
      color: $para-color;
      padding: rem(8px) rem(10px);
      display: flex;
      align-items: center;
      i {
        font-size: rem(20px);
        margin-right: rem(6px); } } } }

.discussion-list {
  .single-discussion {
    padding: rem(10px) 0;
    border-bottom: 1px dashed $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    &__title {
      font-size: rem(15px); } } }

.statistics-list {
  @extend %d-flex;
  .single-stat {
    width: 50%;
    text-align: center;
    padding: rem(15px);
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;
    &:nth-last-child(-n + 2) {
      border-bottom: none; }
    &:nth-of-type(2n + 2) {
      border-right: none; }
    &__nhumber {}
    &__caption {} } }

.contributor-list {
  .single-contributor {
    padding: rem(10px) 0;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    &__thumb {
      width: 30px;
      height: 30px;
      @include border-radius(50%);
      overflow: hidden;
      margin-right: rem(10px);
      img {
        width: inherit;
        height: inherit;
        @extend %obj-fit; } }
    &__name {
      font-size: rem(15px); }
    &__comment {
      margin-left: auto; } } }

.unanswered-list {
  .single-unanswered {
    padding: rem(10px) 0;
    border-bottom: 1px dashed $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    &__top {
      display: flex; }
    &__thumb {
      width: 25px;
      height: 25px;
      @include border-radius(50%);
      overflow: hidden;
      img {
        width: inherit;
        height: inherit;
        @extend %obj-fit; } }
    &__content {
      width: calc(100% - 25px);
      padding-left: rem(10px); }
    &__name {
      font-size: rem(14px); }
    &__title {
      font-size: rem(15px);
      margin-top: rem(10px); } } }


.topic-list {
  .single-topic {
    @extend %d-flex;
    padding: rem(10px) 0;
    border-bottom: 1px dashed $border-color;
    &:first-child {
      padding-top: 0; }
    &:last-child {
      padding-bottom: 0;
      border-bottom: none; }
    &__thumb {
      width: 25px;
      height: 25px;
      @include border-radius(50%);
      overflow: hidden;
      img {
        width: inherit;
        height: inherit;
        @extend %obj-fit; } }
    &__content {
      width: calc(100% - 25px);
      padding-left: rem(15px); }
    &__title {
      font-size: rem(15px); } } }
/* sidebar css end */
